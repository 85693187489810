import type { CtxProductVariant } from '@containex/portal-backend-api-client';
import { filterProductVariantForProvisionType } from '@/util/filterProductVariantForProvisionType';
import { DepotType } from '@containex/portal-backend-dto';

export function optimalVariantForMarket(
    productVariants: CtxProductVariant[],
    marketCode: string | undefined,
    isRental: boolean
): CtxProductVariant | undefined {
    const variantsForMarket = productVariants.filter((variant) => {
        return filterProductVariantForProvisionType(variant, marketCode, isRental);
    });

    let variantsWithPrice = variantsForMarket.filter(
        (variant) => variant.original_price != null && variant.depotQuantity.quantity_available > 0
    );

    if (variantsWithPrice.length === 0) {
        variantsWithPrice = variantsForMarket.filter(
            (variant) =>
                // variant is backordered
                (variant.depotQuantity.quantity_ordered > 0 && variant.depotQuantity.completion_date != null) ||
                // variant is available on demand
                (variant.depotQuantity.depot.type === DepotType.ProductionSite &&
                    variant.depotQuantity.on_demand_completion_date != null)
        );
    }

    const lowestPriceFromVariant = Math.min(
        ...variantsWithPrice.map((variant) => variant.original_price).filter((price) => price != null)
    );
    const variantsWithTheLowestPrice = variantsWithPrice.filter(
        (variant) => variant.original_price === lowestPriceFromVariant
    );

    variantsWithTheLowestPrice.sort((a, b) => {
        if (a.depotQuantity.depot.depot_id === b.depotQuantity.depot.depot_id) {
            return 0;
        }
        return a.depotQuantity.depot.depot_id < b.depotQuantity.depot.depot_id ? -1 : 1;
    });

    return variantsWithTheLowestPrice[0];
}
